import * as React from 'react';
import { AppFactory } from 'app/app-factory';
import { reaction } from 'mobx';
import { PlayerControls } from 'player/views/player-controls';
import { useModelSetup } from 'player/views/player-ui-model-setup';
import {
  PlayerUIConfig,
  usePlayerUIConfig,
} from 'player/views/player-ui-config';
import { Sentence, SpeakerLabel } from 'player/views/player-ui-elements';
import {
  PlayerUIBody,
  PLAYER_CONTROLS_ID,
  SCRIPT_SCROLLING_CONTAINER_ID,
} from 'player/views/player-ui-components';
import {
  LayoutContainer,
  ScriptOuterContainer,
  ScriptInnerContainer,
} from './sb-layout';

import { SbHeader } from './sb-header';
import { ScriptHeader } from './info/script-header';
import { Question } from './info/question';
import { Answer } from './info/answer';
import { SbToolbar } from './sb-toolbar';
import { SbFinalCard } from './sb-final-card';
import { scrollTo, useScrollingObservers } from 'soundbite/fx/scrolling';
import { DebugOverlay } from 'player/views/player-ui-elements/debug-overlay';
import { useOldIosScrollFix } from 'player/views/use-old-ios-scroll-fix';
import { useReaction } from '@common/hooks/use-reaction';
import { toggleClassname } from '@utils/toggle-class';
import { useAudioPauseOnBackground } from '@common/hooks/use-audio-pause-on-background';
import {
  ControlsContainer,
  ScriptPlayingBackdrop,
} from 'study/views/study-layout';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { createLogger } from 'app/logger';
import { elementIds } from 'components/dom-utils/element-ids';
import { KeyboardHelpOverlay } from 'components/ui/keyboard-help-overlay';
import { useWakeLock } from '@core/lib/wake-lock/use-wakelock';
// import { TranslationInlineTip } from 'components/ui/onboarding/translation-tip';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { SoundbiteCountdownNotice } from './soundbite-countdown-notice';

const log = createLogger('soundbite-ui');

const elementTypeMap: { [index: string]: any } = {
  PARAGRAPH: SpeakerLabel,
  SENTENCE: Sentence,
};

const config: PlayerUIConfig = { elementTypeMap };

export const SoundbiteUI = React.memo(
  ({ model }: { model: SoundbiteModel }) => {
    log.debug('SoundbiteUI - render');

    // const model = AppFactory.soundbiteModel;

    // need to assign here to instead of relying on useEffect to correctly handle the flow
    // when one soundbite is visited, then a study session is performed, and then the same
    // original soundbite is revisited (which doesn't retrigger the original useEffect)
    AppFactory.playerModelHandle.model = model;

    usePlayerUIConfig(config); // injects flavor specific behavior into player-ui-components
    useModelSetup(); // setups up membership reconcilers and reaction behaviors shared with both players
    useOldIosScrollFix(); // setups the scroll fix for old ios devices
    useScrollingObservers(); // setups the scroll observers for various elements
    useWakeLock(); // sets up the wake lock for the player UI lifetime

    const scriptContainerRef = React.useRef<HTMLDivElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);

    const onboardingComplete =
      OnboardingService.instance.isInterstitialOnboardingComplete;
    const onboardingSoundbite = model.soundbite.isTheOnboardingSoundbite;
    const onboarding = !onboardingComplete && onboardingSoundbite;

    React.useEffect(() => {
      /// we need to use a reaction to manually update teh showing-translation class
      /// if we use react for it, we will replace the whole DOM underneath and lose
      /// other places where the DOM is updated directly.
      /// This is a non-react way to do things, but it works.
      const reactionDisposer = reaction(
        () => model.translationsShown,
        () => {
          if (model.translationsShown) {
            scrollTo(scriptContainerRef.current);
            /// on small devices we need to wait for the scroll to finish before we add the class
            /// but on desktop it adds an undesirable delay, so we avoid it
            const timeout = window.matchMedia('(max-width: 550px)').matches
              ? 300
              : 0;

            window.setTimeout(() => {
              scriptContainerRef.current?.classList.add('showing-translation');
              // this is to give time for the scroll to happen
              // so the user can see the translation sliding-in
              // but it's not perfect is the answer is too long
            }, timeout);
          } else {
            scriptContainerRef.current?.classList.remove('showing-translation');
          }
        },
        { fireImmediately: true }
      );

      return () => {
        reactionDisposer();
      };
    }, [model.translationsShown]);

    // // redaction tip on the soundbite player needs more thought (not always visible on launch)
    // React.useEffect(() => {
    //   OnboardingService.instance.onPlayerOpened();
    // }, []);

    useReaction(
      () => model.fluentListenMode,
      () => {
        toggleClassname(
          containerRef.current,
          'listen-mode',
          model.fluentListenMode
        );
      }
    );

    useReaction(
      () => model.isPlaying,
      () => {
        const scriptContainer = document.getElementById(
          SCRIPT_SCROLLING_CONTAINER_ID
        );
        toggleClassname(scriptContainer, 'playing', model.isPlaying);
      }
    );

    useAudioPauseOnBackground();

    return (
      <>
        <LayoutContainer ref={containerRef}>
          <SbHeader onboarding={onboarding} />

          <ScriptOuterContainer id={SCRIPT_SCROLLING_CONTAINER_ID}>
            <ScriptHeader model={model} />
            <div id={elementIds.QUESTION_AREA}>
              <Question excerpt={model.excerptData} />
            </div>
            <div id={elementIds.AFTER_QUESTION_BEACON}></div>
            <div>
              <SbToolbar excerpt={model.excerptData} />
              <ScriptInnerContainer ref={scriptContainerRef}>
                <PlayerUIBody />
              </ScriptInnerContainer>
            </div>
            <div id={elementIds.END_AREA}>
              {/* <TranslationInlineTip model={model} /> */}
              <Answer model={model} />
              <SbFinalCard onboarding={onboarding} />
            </div>
            <SoundbiteCountdownNotice model={model} />
            <ScriptPlayingBackdrop />
          </ScriptOuterContainer>
          <ControlsContainer id={PLAYER_CONTROLS_ID}>
            <PlayerControls />
          </ControlsContainer>
          <DebugOverlay />
          <KeyboardHelpOverlay mode="soundbites" />
        </LayoutContainer>
      </>
    );
  }
);
